
import { mapMutations } from "vuex";

export default {
	props: {
		modalMenuActive: {
			type: Boolean,
			default: false
		}
	},
	methods: {
		...mapMutations({
			setModalMenu: 'modal/modal-menu/setModalMenu',
			setModalMarks: 'modal/modal-marks/setModalMarks',
			setModalSearch: 'modal/modal-search/setModalSearch',
		}),
		closeModals() {
			this.setModalMenu(false)
			this.setModalMarks(false)
			this.setModalSearch(false)
		}
	},
	computed: {
		nav() {
			return [
				{
					title: 'В наличии',
					link: '/cars'
				},
				{
					title: 'Автокредит',
					link: '/credit'
				},
				{
					title: 'Trade-In',
					link: '/exchange'
				},
				// {
				// 	title: 'ВЫКУП',
				// 	link: '/buyout'
				// },
				{
					title: 'Рассрочка',
					link: '/installments'
				},

				{
					title: 'Контакты',
					link: '/contacts'
				},

			]
		},

		route() {
			return this.$route.path
		}
	},

}
